require("./src/styles/global.scss");

// For use of URLSearchParams in IE11
require("url-search-params-polyfill");
const React = require("react");
const AuthProvider = require("./src/utils/AuthContext").default;
const { prefersReducedMotion } = require("./src/utils/user-preferences");

// Important for code embeds that change the language on the URL but must not scroll to the top.
exports.shouldUpdateScroll = exports.shouldUpdateScroll = ({
  routerProps,
  prevRouterProps
}) => {
  const { scrollToElementId } = routerProps.location.state ?? {};
  if (scrollToElementId) {
    document.querySelector(scrollToElementId)?.scrollIntoView({
      behavior: prefersReducedMotion() ? "auto" : "smooth"
    });
    return false;
  }
  const result =
    routerProps.location &&
    prevRouterProps &&
    prevRouterProps.location &&
    prevRouterProps.location.pathname !== routerProps.location.pathname;
  return result;
};

exports.onRouteUpdate = ({ prevLocation }) => {
  // Skip link handling
  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav");
    if (skipLink) {
      skipLink.focus();
    }
  }

  return null;
};

exports.wrapRootElement = ({ element }) => {
  return <AuthProvider>{element}</AuthProvider>;
};
