exports.components = {
  "component---src-templates-about-page-tsx": () => import("./../../../src/templates/AboutPage.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-accessories-page-tsx": () => import("./../../../src/templates/AccessoriesPage.tsx" /* webpackChunkName: "component---src-templates-accessories-page-tsx" */),
  "component---src-templates-award-page-tsx": () => import("./../../../src/templates/AwardPage.tsx" /* webpackChunkName: "component---src-templates-award-page-tsx" */),
  "component---src-templates-awards-page-tsx": () => import("./../../../src/templates/AwardsPage.tsx" /* webpackChunkName: "component---src-templates-awards-page-tsx" */),
  "component---src-templates-case-studies-page-tsx": () => import("./../../../src/templates/CaseStudiesPage.tsx" /* webpackChunkName: "component---src-templates-case-studies-page-tsx" */),
  "component---src-templates-case-study-page-tsx": () => import("./../../../src/templates/CaseStudyPage.tsx" /* webpackChunkName: "component---src-templates-case-study-page-tsx" */),
  "component---src-templates-classroom-resource-page-tsx": () => import("./../../../src/templates/ClassroomResourcePage.tsx" /* webpackChunkName: "component---src-templates-classroom-resource-page-tsx" */),
  "component---src-templates-classroom-resources-page-tsx": () => import("./../../../src/templates/ClassroomResourcesPage.tsx" /* webpackChunkName: "component---src-templates-classroom-resources-page-tsx" */),
  "component---src-templates-design-your-microbit-page-design-your-microbit-page-tsx": () => import("./../../../src/templates/DesignYourMicrobitPage/DesignYourMicrobitPage.tsx" /* webpackChunkName: "component---src-templates-design-your-microbit-page-design-your-microbit-page-tsx" */),
  "component---src-templates-dyb-generic-page-tsx": () => import("./../../../src/templates/DybGenericPage.tsx" /* webpackChunkName: "component---src-templates-dyb-generic-page-tsx" */),
  "component---src-templates-dyb-global-goals-page-tsx": () => import("./../../../src/templates/DybGlobalGoalsPage.tsx" /* webpackChunkName: "component---src-templates-dyb-global-goals-page-tsx" */),
  "component---src-templates-dyb-winners-page-tsx": () => import("./../../../src/templates/DybWinnersPage.tsx" /* webpackChunkName: "component---src-templates-dyb-winners-page-tsx" */),
  "component---src-templates-event-page-tsx": () => import("./../../../src/templates/EventPage.tsx" /* webpackChunkName: "component---src-templates-event-page-tsx" */),
  "component---src-templates-events-page-tsx": () => import("./../../../src/templates/EventsPage.tsx" /* webpackChunkName: "component---src-templates-events-page-tsx" */),
  "component---src-templates-features-page-tsx": () => import("./../../../src/templates/FeaturesPage.tsx" /* webpackChunkName: "component---src-templates-features-page-tsx" */),
  "component---src-templates-foundation-report-page-tsx": () => import("./../../../src/templates/FoundationReportPage.tsx" /* webpackChunkName: "component---src-templates-foundation-report-page-tsx" */),
  "component---src-templates-foundation-reports-page-tsx": () => import("./../../../src/templates/FoundationReportsPage.tsx" /* webpackChunkName: "component---src-templates-foundation-reports-page-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("./../../../src/templates/GenericPage.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-getting-started-page-tsx": () => import("./../../../src/templates/GettingStartedPage.tsx" /* webpackChunkName: "component---src-templates-getting-started-page-tsx" */),
  "component---src-templates-hex-flash-tool-page-hex-flash-tool-page-tsx": () => import("./../../../src/templates/HexFlashToolPage/HexFlashToolPage.tsx" /* webpackChunkName: "component---src-templates-hex-flash-tool-page-hex-flash-tool-page-tsx" */),
  "component---src-templates-landing-page-default-tsx": () => import("./../../../src/templates/LandingPageDefault.tsx" /* webpackChunkName: "component---src-templates-landing-page-default-tsx" */),
  "component---src-templates-landing-page-what-is-microbit-tsx": () => import("./../../../src/templates/LandingPageWhatIsMicrobit.tsx" /* webpackChunkName: "component---src-templates-landing-page-what-is-microbit-tsx" */),
  "component---src-templates-lesson-page-tsx": () => import("./../../../src/templates/LessonPage.tsx" /* webpackChunkName: "component---src-templates-lesson-page-tsx" */),
  "component---src-templates-lessons-page-tsx": () => import("./../../../src/templates/LessonsPage.tsx" /* webpackChunkName: "component---src-templates-lessons-page-tsx" */),
  "component---src-templates-lets-code-page-tsx": () => import("./../../../src/templates/LetsCodePage.tsx" /* webpackChunkName: "component---src-templates-lets-code-page-tsx" */),
  "component---src-templates-make-page-tsx": () => import("./../../../src/templates/MakePage.tsx" /* webpackChunkName: "component---src-templates-make-page-tsx" */),
  "component---src-templates-makes-page-tsx": () => import("./../../../src/templates/MakesPage.tsx" /* webpackChunkName: "component---src-templates-makes-page-tsx" */),
  "component---src-templates-news-article-page-tsx": () => import("./../../../src/templates/NewsArticlePage.tsx" /* webpackChunkName: "component---src-templates-news-article-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-playground-survey-guide-page-tsx": () => import("./../../../src/templates/PlaygroundSurveyGuidePage.tsx" /* webpackChunkName: "component---src-templates-playground-survey-guide-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-products-page-tsx": () => import("./../../../src/templates/ProductsPage.tsx" /* webpackChunkName: "component---src-templates-products-page-tsx" */),
  "component---src-templates-research-page-tsx": () => import("./../../../src/templates/ResearchPage.tsx" /* webpackChunkName: "component---src-templates-research-page-tsx" */),
  "component---src-templates-teach-featured-page-tsx": () => import("./../../../src/templates/TeachFeaturedPage.tsx" /* webpackChunkName: "component---src-templates-teach-featured-page-tsx" */),
  "component---src-templates-teach-page-tsx": () => import("./../../../src/templates/TeachPage.tsx" /* webpackChunkName: "component---src-templates-teach-page-tsx" */),
  "component---src-templates-unit-of-work-page-tsx": () => import("./../../../src/templates/UnitOfWorkPage.tsx" /* webpackChunkName: "component---src-templates-unit-of-work-page-tsx" */),
  "component---src-templates-user-guide-page-tsx": () => import("./../../../src/templates/UserGuidePage.tsx" /* webpackChunkName: "component---src-templates-user-guide-page-tsx" */),
  "component---src-templates-where-to-buy-page-tsx": () => import("./../../../src/templates/WhereToBuyPage.tsx" /* webpackChunkName: "component---src-templates-where-to-buy-page-tsx" */)
}

