import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import flags from "../utils/flags";

export interface Favourite {
  id: string;
}

export const enum LoginStatus {
  TBD,
  LOGGED_OUT,
  LOGGED_IN
}

export interface UserInfo {
  status: LoginStatus;
  email?: string;
  favourites?: Favourite[];
}

type AuthContextValue = {
  userInfo: UserInfo;
  addFavourite: (id: string) => Promise<void>;
  removeFavourite: (id: string) => Promise<void>;
};

const fetchFavourites = async (): Promise<Favourite[] | undefined> => {
  const response = await fetch("/api/v1/favourites/", {
    credentials: "same-origin"
  });
  if (response.ok) {
    const parsedResponse = await response.json();
    return parsedResponse.data as Favourite[];
  }
};

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const useAuth = (): AuthContextValue => {
  const userInfo = useContext(AuthContext);
  if (!userInfo) {
    throw new Error("Missing provider");
  }
  return userInfo;
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    status: LoginStatus.TBD
  });

  useEffect(() => {
    const initAsync = async () => {
      try {
        const response = await fetch("/api/v1/accounts/me", {
          credentials: "same-origin"
        });
        if (response.ok) {
          const json = await response.json();
          if (json.type !== "temporary") {
            return setUserInfo({
              email: json.email,
              status: LoginStatus.LOGGED_IN
            });
          }
        }
        setUserInfo({ status: LoginStatus.LOGGED_OUT });
      } catch (e) {
        setUserInfo({ status: LoginStatus.LOGGED_OUT });
      }
    };

    initAsync();
  }, []);

  useEffect(() => {
    const initAsync = async () => {
      if (userInfo.status !== LoginStatus.LOGGED_IN) {
        return;
      }
      const favourites = await fetchFavourites();
      setUserInfo(info => ({
        ...info,
        favourites
      }));
    };
    initAsync();
  }, [userInfo.status]);

  const addFavourite = useCallback(async (id: string): Promise<void> => {
    const revertChange = async () => {
      const favourites = await fetchFavourites();
      setUserInfo(info => ({
        ...info,
        favourites
      }));
    };
    setUserInfo(info => ({
      ...info,
      favourites: [{ id }].concat(info.favourites ?? [])
    }));
    try {
      const response = await fetch(`/api/v1/favourites/${id}`, {
        method: "PUT",
        credentials: "same-origin"
      });
      if (!response.ok) {
        revertChange();
      }
    } catch (e) {
      revertChange();
    }
  }, []);

  const removeFavourite = useCallback(async (id: string): Promise<void> => {
    const revertChange = async () => {
      const favourites = await fetchFavourites();
      setUserInfo(info => ({
        ...info,
        favourites
      }));
    };
    setUserInfo(info => ({
      ...info,
      favourites: info.favourites?.filter(f => f.id !== id)
    }));
    try {
      const response = await fetch(`/api/v1/favourites/${id}`, {
        method: "DELETE",
        credentials: "same-origin"
      });
      if (!response.ok) {
        revertChange();
      }
    } catch (e) {
      revertChange();
    }
  }, []);

  const value = useMemo(() => {
    return { userInfo, addFavourite, removeFavourite };
  }, [userInfo, addFavourite, removeFavourite]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthProviderStub = ({ children }: { children: ReactNode }) => {
  const [userInfo] = useState<UserInfo>({
    status: LoginStatus.LOGGED_OUT
  });
  const addFavourite = useCallback(async (_id: string): Promise<void> => {},
  []);
  const removeFavourite = useCallback(async (_id: string): Promise<void> => {},
  []);
  const value = useMemo(() => {
    return { userInfo, addFavourite, removeFavourite };
  }, [userInfo, addFavourite, removeFavourite]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default flags.loginDemo ? AuthProvider : AuthProviderStub;
